<template>
  <f7-page class="future-wine-list-page" infinite :infinite-distance="150" :infinite-preloader="false" @infinite="loadMore">
    <template #fixed>
        <ProductNavigationComponent :title="$t.getTranslation('LBL_WINE_SEARCHER')" type="back" :search="false" />
    </template>
    <section class="wine-container">
        <FutureWineListComponent ref="futurewinelist" :f7route="f7route"/> 
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";
// import FutureWineListComponent from "@/components/FutureWineListComponent.vue";
// import SearchComponent from "@/components/SearchComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));
const FutureWineListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "future-wine-list" */ /* webpackMode: "lazy" */ "@/components/FutureWineListComponent.vue"));

export default defineComponent({
  name: "WineSearcherPage",
  components: {
    ProductNavigationComponent,
    FutureWineListComponent
    // SearchComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore();
   
    return {store};
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      await this.$refs.futurewinelist.loadMore();
      this.showPreloader = false;
    },
    onSearch(searchValue) {
      // this.$refs.postListComponent.search(searchValue);
    }
  },
});
</script>

<style scoped>

.wine-container{
  width: calc(100% - 40px);
  margin: 20px auto 0px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
}
</style>